import React from "react"
import { Link } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import Header from "../components/header"
import Illustration from "../assets/images/404.png"
import IllustrationMobile from "../assets/images/404-mobile.png"
import SEO from "../components/seo"

const PageNotFound = () => {
  return (
    <ParallaxProvider>
      <SEO
        pageTitle="Page Not Found"
        title="404: Page Not Found | Servbees"
        description="You might have lost your way, Buzzybee."
      />
      <Header />
      <div className="body-wrapper">
        <div className="row">
          <main className="notfound">
            <div className="content-wrapper">
              <div className="img-holder">
                <img
                  src={Illustration}
                  alt=""
                  className="notfound-image for-desktop"
                />
                <img
                  src={IllustrationMobile}
                  alt=""
                  className="notfound-image for-mobile"
                />
              </div>
              <div className="text-holder">
                <p className="subtext">Roses are red. Violets are blue.</p>
                <h3 className="heading">
                  This page can't be found,
                  <br />
                  We need to redirect you.
                </h3>
                <p className="bottom-text">
                  You might have lost your way, Buzzybee.
                </p>
                <p className="bottom-text">
                  Check our <Link to="/faq"> FAQs</Link> or{" "}
                  <Link to="/contact"> Contact Us</Link> to get you back on
                  track.
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </ParallaxProvider>
  )
}

export default PageNotFound
